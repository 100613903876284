body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.item1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*flex-grow: 1;*/
}

.form-style-7 {
  max-width: 300px;
  margin: 50px auto;
  background: rgba(0, 0, 0, 0);
  border-radius: 2px;
  padding: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
  
}
.form-style-7 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.form-style-7 li {
  display: block;
  padding: 9px;
  border: 1px solid #ac148b;
  margin-bottom: 30px;
  border-radius: 3px;
}
.form-style-7 li > label {
  display: block;
  float: left;
  margin-top: -19px;
  background: #282c34;
  height: 14px;
  padding: 2px 5px 2px 5px;
  color: #ac148b;
  font-size: 15px;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.form-style-7 li > .info,
.form-style-7 li > .warn {
	display: block;
	float: left;
	margin-top: -19px;
	background: #282c34;
	height: 14px;
	padding: 2px 5px 2px 5px;
	color: #ac148b;
	font-size: 15px;
	overflow: hidden;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
  }

.form-style-7 h2,
.form-style-7 input[type="text"],
.form-style-7 input[type="date"],
.form-style-7 input[type="datetime"],
.form-style-7 input[type="email"],
.form-style-7 input[type="number"],
.form-style-7 input[type="search"],
.form-style-7 input[type="time"],
.form-style-7 input[type="url"],
.form-style-7 input[type="password"],
.form-style-7 textarea,
.form-style-7 select {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  display: block;
  outline: none;
  border: none;
  height: auto;
  line-height: 25px;
  font-size: 16px;
  padding: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  background: #50505000;
  color: #ffffff;
}
.form-style-7 li > span {
  background: #0e0e0e;
  display: block;
  padding: 3px;
  margin: 0 -9px -9px -9px;
  text-align: center;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}


.form-style-8 {
  max-width: 300px;
  margin: 50px auto;
  background: rgba(0, 0, 0, 0);
  border-radius: 2px;
  padding: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.form-style-8 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.form-style-8 li {
  display: block;
  padding: 9px;
  border: 1px solid #ac148b;
  margin-bottom: 30px;
  border-radius: 3px;
}
.form-style-8 li > label {
  display: block;
  float: left;
  margin-top: -19px;
  background: #282c34;
  height: 14px;
  padding: 2px 5px 2px 5px;
  color: #ac148b;
  font-size: 15px;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}
.form-style-8 h2,
.form-style-8 input[type="text"],
.form-style-8 input[type="date"],
.form-style-8 input[type="datetime"],
.form-style-8 input[type="email"],
.form-style-8 input[type="number"],
.form-style-8 input[type="search"],
.form-style-8 input[type="time"],
.form-style-8 input[type="url"],
.form-style-8 input[type="password"],
.form-style-8 textarea,
.form-style-8 select {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  display: block;
  outline: none;
  border: none;
  height: auto;
  line-height: 25px;
  font-size: 16px;
  padding: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  background: #50505000;
  color: #ffffff;
}
.form-style-8 li > span {
  background: #0e0e0e;
  display: block;
  padding: 3px;
  margin: 0 -9px -9px -9px;
  text-align: center;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}
